"use client";

import { useState, useCallback } from "react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown, Globe } from "lucide-react";
import { useTranslation } from "@/hooks/use-translation";
import { trackEvent } from "@/lib/analytics";

const languages = [
  { code: "en", name: "English", flag: "🇬🇧" },
  { code: "de", name: "Deutsch", flag: "🇩🇪" },
  { code: "it", name: "Italiano", flag: "🇮🇹" },
  { code: "fr", name: "Français", flag: "🇫🇷" },
  { code: "ja", name: "日本語", flag: "🇯🇵" },
];

export function LanguageSelector() {
  const { language, setLanguage } = useTranslation();
  const [open, setOpen] = useState(false);

  const currentLanguage =
    languages.find((lang) => lang.code === language) || languages[0];

  const handleLanguageChange = useCallback(
    (langCode: string) => {
      setLanguage(langCode as any);
      setOpen(false);

      // Track language change
      trackEvent("language_changed", { language: langCode });
    },
    [setLanguage]
  );

  return (
    <div className="relative">
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="pl-2 pr-2 justify-between sm:w-[160px] sm:pl-8"
          >
            <Globe className="h-4 w-4 text-gray-500 mr-1 sm:absolute sm:left-2 sm:top-1/2 sm:transform sm:-translate-y-1/2" />
            <span className="hidden sm:flex sm:items-center sm:gap-2">
              <span className="text-lg">{currentLanguage.flag}</span>
              <span>{currentLanguage.name}</span>
            </span>
            <span className="flex sm:hidden text-lg">
              {currentLanguage.flag}
            </span>
            <ChevronDown className="h-4 w-4 opacity-50 ml-1 sm:ml-0" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {languages.map((lang) => (
            <DropdownMenuItem
              key={lang.code}
              className={`flex items-center gap-2 ${
                lang.code === language ? "bg-primary/10" : ""
              }`}
              onClick={() => handleLanguageChange(lang.code)}
            >
              <span className="text-lg">{lang.flag}</span>
              <span>{lang.name}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
