/**
 * Initializes Google Analytics 4
 */
export function initializeGA4(): void {
  if (typeof window === "undefined" || !process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID) {
    return
  }

  // Check if GA script is already loaded
  if (document.querySelector(`script[src*="googletagmanager.com/gtag/js"]`)) {
    return
  }

  // Add GA script
  const script = document.createElement("script")
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`
  script.async = true
  document.head.appendChild(script)

  // Initialize dataLayer and gtag function
  window.dataLayer = window.dataLayer || []
  function gtag(...args: any[]) {
    window.dataLayer.push(args)
  }

  gtag("js", new Date())
  gtag("config", process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
    page_path: window.location.pathname,
    cookie_flags: "SameSite=None;Secure",
  })
}

/**
 * Tracks a page view in GA4
 */
export function trackPageView(path: string): void {
  if (typeof window === "undefined" || !hasAnalyticsConsent()) {
    return
  }

  if (window.gtag) {
    window.gtag("event", "page_view", {
      page_path: path,
      page_title: document.title,
    })
  }
}

/**
 * Tracks an event in GA4
 */
export function trackEvent(eventName: string, params: Record<string, any> = {}): void {
  if (typeof window === "undefined" || !hasAnalyticsConsent()) {
    return
  }

  if (window.gtag) {
    window.gtag("event", eventName, params)
  }
}

/**
 * Checks if user has consented to analytics
 */
export function hasAnalyticsConsent(): boolean {
  return localStorage.getItem("cookieConsent") === "accepted"
}

