"use client";

import {
  createContext,
  useContext,
  useState,
  useEffect,
  type ReactNode,
} from "react";
import type { Language, TranslationContextType } from "@/types";

const translations: Record<Language, Record<string, string>> = {
  en: {
    "discord.id": "Discord ID",
    search: "Search",
    searching: "Searching...",
    "no.recent.searches": "No Recent Searches",
    "recent.searches": "Recent Searches",
    "enter.discord.id": "Enter Discord ID",
    "view.on.discord": "View on Discord",
    home: "Home",
    type: "Type",
    accent: "Accent",
    "created.on": "Created On",
    "account.age": "Account Age",
    "tooltip.discord.id":
      "Enter a Discord user ID to look up their profile information.",
    "tooltip.learn.how": "Learn how to find IDs",
    footer: "Made with ❤️ by Lulu - Independent from Discord Inc.",
    copied: "Copied!",
    "color.copied": "Color copied to clipboard",
    "click.to.copy.color": "Click to copy color code",
    "no.accent.color": "No accent color",
    error: "Error",
    "error.fetch": "Failed to fetch user data",
    "add.favorite": "Favorite",
    favorited: "Favorited",
    "favorite.added": "Added to Favorites",
    "favorite.removed": "Removed from Favorites",
    "user.added.to.favorites": "User has been added to your favorites",
    "user.removed.from.favorites": "User has been removed from your favorites",
    "validation.error": "Validation Error",
    "id.numbers.only": "Discord ID must contain only numbers",
    "id.too.short": "Discord ID must be at least 15 characters",
    "id.too.long": "Discord ID must be at most 22 characters",
    "cookie.consent.title": "This website uses cookies",
    "cookie.consent.description":
      "We use cookies to analyze site traffic and optimize your experience. By accepting, you consent to our use of cookies.",
    "cookie.accept": "Accept",
    "cookie.decline": "Decline",
    favorites: "Favorites",
    "no.favorites": "No Favorites",
    remove: "Remove",
    "back.to.home": "Back to Home",
    "server.error": "Server Error",
    "not.found": "Not Found",
    "unknown.error": "Unknown Error",
    "error.code": "Error Code",
  },
  de: {
    "discord.id": "Discord ID",
    search: "Suchen",
    searching: "Suche...",
    "no.recent.searches": "Keine kürzlichen Suchen",
    "recent.searches": "Kürzliche Suchen",
    "enter.discord.id": "Discord ID eingeben",
    "view.on.discord": "Auf Discord ansehen",
    home: "Startseite",
    type: "Typ",
    accent: "Akzent",
    "created.on": "Erstellt am",
    "account.age": "Kontoalter",
    "tooltip.discord.id":
      "Geben Sie eine Discord-Benutzer-ID ein, um ihre Profilinformationen nachzuschlagen.",
    "tooltip.learn.how": "Erfahren Sie, wie Sie IDs finden",
    footer: "Gemacht mit ❤️ von Lulu - Unabhängig von Discord Inc.",
    copied: "Kopiert!",
    "color.copied": "Farbe in die Zwischenablage kopiert",
    "click.to.copy.color": "Klicken, um Farbcode zu kopieren",
    "no.accent.color": "Keine Akzentfarbe",
    error: "Fehler",
    "error.fetch": "Fehler beim Abrufen der Benutzerdaten",
    "add.favorite": "Favorisieren",
    favorited: "Favorisiert",
    "favorite.added": "Zu Favoriten hinzugefügt",
    "favorite.removed": "Aus Favoriten entfernt",
    "user.added.to.favorites": "Benutzer wurde zu Ihren Favoriten hinzugefügt",
    "user.removed.from.favorites":
      "Benutzer wurde aus Ihren Favoriten entfernt",
    "validation.error": "Validierungsfehler",
    "id.numbers.only": "Discord ID darf nur Zahlen enthalten",
    "id.too.short": "Discord ID muss mindestens 15 Zeichen lang sein",
    "id.too.long": "Discord ID darf höchstens 22 Zeichen lang sein",
    "cookie.consent.title": "Diese Website verwendet Cookies",
    "cookie.consent.description":
      "Wir verwenden Cookies, um den Website-Verkehr zu analysieren und Ihre Erfahrung zu optimieren. Durch Akzeptieren stimmen Sie der Verwendung von Cookies zu.",
    "cookie.accept": "Akzeptieren",
    "cookie.decline": "Ablehnen",
    favorites: "Favoriten",
    "no.favorites": "Keine Favoriten",
    remove: "Entfernen",
    "back.to.home": "Zurück zur Startseite",
    "server.error": "Serverfehler",
    "not.found": "Nicht gefunden",
    "unknown.error": "Unbekannter Fehler",
    "error.code": "Fehlercode",
  },
  // Other languages follow the same pattern...
  it: {
    "discord.id": "ID Discord",
    search: "Cerca",
    searching: "Ricerca in corso...",
    "no.recent.searches": "Nessuna ricerca recente",
    "recent.searches": "Ricerche recenti",
    "enter.discord.id": "Inserisci ID Discord",
    "view.on.discord": "Visualizza su Discord",
    home: "Home",
    type: "Tipo",
    accent: "Accento",
    "created.on": "Creato il",
    "account.age": "Età dell'account",
    "tooltip.discord.id":
      "Inserisci l'ID utente Discord per cercare le informazioni del profilo.",
    "tooltip.learn.how": "Scopri come trovare gli ID",
    footer: "Realizzato con ❤️ da Lulu - Indipendente da Discord Inc.",
    copied: "Copiato!",
    "color.copied": "Colore copiato negli appunti",
    "click.to.copy.color": "Clicca per copiare il codice colore",
    "no.accent.color": "Nessun colore di accento",
    error: "Errore",
    "error.fetch": "Impossibile recuperare i dati dell'utente",
    "add.favorite": "Preferito",
    favorited: "Preferito",
    "favorite.added": "Aggiunto ai Preferiti",
    "favorite.removed": "Rimosso dai Preferiti",
    "user.added.to.favorites": "L'utente è stato aggiunto ai tuoi preferiti",
    "user.removed.from.favorites":
      "L'utente è stato rimosso dai tuoi preferiti",
    "validation.error": "Errore di Validazione",
    "id.numbers.only": "L'ID Discord deve contenere solo numeri",
    "id.too.short": "L'ID Discord deve essere di almeno 15 caratteri",
    "id.too.long": "L'ID Discord deve essere di massimo 22 caratteri",
    "cookie.consent.title": "Questo sito utilizza i cookie",
    "cookie.consent.description":
      "Utilizziamo i cookie per analizzare il traffico del sito e ottimizzare la tua esperienza. Accettando, acconsenti all'uso dei cookie.",
    "cookie.accept": "Accetta",
    "cookie.decline": "Rifiuta",
    favorites: "Preferiti",
    "no.favorites": "Nessun Preferito",
    remove: "Rimuovi",
    "back.to.home": "Torna alla Home",
    "server.error": "Errore del Server",
    "not.found": "Non Trovato",
    "unknown.error": "Errore Sconosciuto",
    "error.code": "Codice Errore",
  },
  fr: {
    "discord.id": "ID Discord",
    search: "Rechercher",
    searching: "Recherche en cours...",
    "no.recent.searches": "Aucune recherche récente",
    "recent.searches": "Recherches récentes",
    "enter.discord.id": "Entrez l'ID Discord",
    "view.on.discord": "Voir sur Discord",
    home: "Accueil",
    type: "Type",
    accent: "Accent",
    "created.on": "Créé le",
    "account.age": "Âge du compte",
    "tooltip.discord.id":
      "Entrez un ID d'utilisateur Discord pour rechercher les informations de profil.",
    "tooltip.learn.how": "Apprenez à trouver les IDs",
    footer: "Fait avec ❤️ par Lulu - Indépendant de Discord Inc.",
    copied: "Copié !",
    "color.copied": "Couleur copiée dans le presse-papiers",
    "click.to.copy.color": "Cliquez pour copier le code couleur",
    "no.accent.color": "Pas de couleur d'accent",
    error: "Erreur",
    "error.fetch": "Échec de la récupération des données utilisateur",
    "add.favorite": "Favoris",
    favorited: "Favori",
    "favorite.added": "Ajouté aux Favoris",
    "favorite.removed": "Retiré des Favoris",
    "user.added.to.favorites": "L'utilisateur a été ajouté à vos favoris",
    "user.removed.from.favorites": "L'utilisateur a été retiré de vos favoris",
    "validation.error": "Erreur de Validation",
    "id.numbers.only": "L'ID Discord ne doit contenir que des chiffres",
    "id.too.short": "L'ID Discord doit comporter au moins 15 caractères",
    "id.too.long": "L'ID Discord doit comporter au maximum 22 caractères",
    "cookie.consent.title": "Ce site utilise des cookies",
    "cookie.consent.description":
      "Nous utilisons des cookies pour analyser le trafic du site et optimiser votre expérience. En acceptant, vous consentez à notre utilisation des cookies.",
    "cookie.accept": "Accepter",
    "cookie.decline": "Refuser",
    favorites: "Favoris",
    "no.favorites": "Aucun Favori",
    remove: "Supprimer",
    "back.to.home": "Retour à l'Accueil",
    "server.error": "Erreur Serveur",
    "not.found": "Non Trouvé",
    "unknown.error": "Erreur Inconnue",
    "error.code": "Code d'Erreur",
  },
  ja: {
    "discord.id": "Discord ID",
    search: "検索",
    searching: "検索中...",
    "no.recent.searches": "最近の検索はありません",
    "recent.searches": "最近の検索",
    "enter.discord.id": "Discord IDを入力",
    "view.on.discord": "Discordで表示",
    home: "ホーム",
    type: "タイプ",
    accent: "アクセント",
    "created.on": "作成日",
    "account.age": "アカウント年齢",
    "tooltip.discord.id":
      "Discord ユーザーIDを入力してプロフィール情報を検索します。",
    "tooltip.learn.how": "IDの見つけ方を学ぶ",
    footer: "Lulu によって❤️で作られました - Discord Inc.から独立",
    copied: "コピーしました！",
    "color.copied": "色をクリップボードにコピーしました",
    "click.to.copy.color": "クリックして色コードをコピー",
    "no.accent.color": "アクセントカラーなし",
    error: "エラー",
    "error.fetch": "ユーザーデータの取得に失敗しました",
    "add.favorite": "お気に入り",
    favorited: "お気に入り済み",
    "favorite.added": "お気に入りに追加",
    "favorite.removed": "お気に入りから削除",
    "user.added.to.favorites": "ユーザーがお気に入りに追加されました",
    "user.removed.from.favorites": "ユーザーがお気に入りから削除されました",
    "validation.error": "検証エラー",
    "id.numbers.only": "Discord IDは数字のみを含む必要があります",
    "id.too.short": "Discord IDは少なくとも15文字必要です",
    "id.too.long": "Discord IDは最大22文字までです",
    "cookie.consent.title": "このウェブサイトはCookieを使用しています",
    "cookie.consent.description":
      "サイトのトラフィックを分析し、エクスペリエンスを最適化するためにCookieを使用しています。承諾することにより、Cookieの使用に同意したことになります。",
    "cookie.accept": "承諾する",
    "cookie.decline": "拒否する",
    favorites: "お気に入り",
    "no.favorites": "お気に入りはありません",
    remove: "削除",
    "back.to.home": "ホームに戻る",
    "server.error": "サーバーエラー",
    "not.found": "見つかりません",
    "unknown.error": "不明なエラー",
    "error.code": "エラーコード",
  },
};

const TranslationContext = createContext<TranslationContextType>({
  language: "en",
  setLanguage: () => {},
  t: (key) => key,
});

export function TranslationProvider({ children }: { children: ReactNode }) {
  const [language, setLanguage] = useState<Language>("en");

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") as Language;
    if (savedLanguage && translations[savedLanguage]) {
      setLanguage(savedLanguage);
    } else {
      // Try to detect browser language
      const browserLang = navigator.language.split("-")[0] as Language;
      if (translations[browserLang]) {
        setLanguage(browserLang);
        localStorage.setItem("language", browserLang);
      }
    }
  }, []);

  const changeLanguage = (lang: Language) => {
    setLanguage(lang);
    localStorage.setItem("language", lang);
  };

  const t = (key: string) => {
    return translations[language]?.[key] || translations.en[key] || key;
  };

  return (
    <TranslationContext.Provider
      value={{ language, setLanguage: changeLanguage, t }}
    >
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  return useContext(TranslationContext);
}
