import type { FavoriteUser } from "@/types";

/**
 * Gets recent searches from localStorage
 */
export function getRecentSearches(): string[] {
  if (typeof window === "undefined") {
    return [];
  }

  try {
    return JSON.parse(localStorage.getItem("recentSearches") || "[]");
  } catch (error) {
    console.error("Error parsing recent searches:", error);
    return [];
  }
}

/**
 * Adds a search to recent searches
 */
export function addRecentSearch(id: string): void {
  if (typeof window === "undefined") {
    return;
  }

  try {
    const recentSearches = getRecentSearches();

    // Remove if already exists
    const filteredSearches = recentSearches.filter(
      (searchId) => searchId !== id
    );

    // Add to beginning
    filteredSearches.unshift(id);

    // Limit to 5 items
    // if (filteredSearches.length > 5) {
    //   filteredSearches.pop()
    // }

    localStorage.setItem("recentSearches", JSON.stringify(filteredSearches));

    // Trigger storage event for other components
    window.dispatchEvent(new Event("storage"));
  } catch (error) {
    console.error("Error adding recent search:", error);
  }
}

/**
 * Gets favorite users from localStorage
 */
export function getFavoriteUsers(): FavoriteUser[] {
  if (typeof window === "undefined") {
    return [];
  }

  try {
    return JSON.parse(localStorage.getItem("favoriteUsers") || "[]");
  } catch (error) {
    console.error("Error parsing favorite users:", error);
    return [];
  }
}

/**
 * Checks if a user is in favorites
 */
export function isUserFavorite(userId: string): boolean {
  const favorites = getFavoriteUsers();
  return favorites.some((user) => user.id === userId);
}

/**
 * Adds a user to favorites
 */
export function addFavoriteUser(user: FavoriteUser): void {
  if (typeof window === "undefined") {
    return;
  }

  try {
    const favorites = getFavoriteUsers();

    // Check if already exists
    if (!favorites.some((fav) => fav.id === user.id)) {
      favorites.push(user);
      localStorage.setItem("favoriteUsers", JSON.stringify(favorites));

      // Trigger storage event for other components
      window.dispatchEvent(new Event("storage"));
    }
  } catch (error) {
    console.error("Error adding favorite user:", error);
  }
}

/**
 * Removes a user from favorites
 */
export function removeFavoriteUser(userId: string): void {
  if (typeof window === "undefined") {
    return;
  }

  try {
    const favorites = getFavoriteUsers();
    const updatedFavorites = favorites.filter((user) => user.id !== userId);

    localStorage.setItem("favoriteUsers", JSON.stringify(updatedFavorites));

    // Trigger storage event for other components
    window.dispatchEvent(new Event("storage"));
  } catch (error) {
    console.error("Error removing favorite user:", error);
  }
}

/**
 * Gets cookie consent status
 */
export function getCookieConsent(): string | null {
  if (typeof window === "undefined") {
    return null;
  }

  return localStorage.getItem("cookieConsent");
}

/**
 * Sets cookie consent status
 */
export function setCookieConsent(consent: boolean): void {
  if (typeof window === "undefined") {
    return;
  }

  localStorage.setItem("cookieConsent", consent ? "accepted" : "rejected");
}
