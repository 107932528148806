"use client"

import { useState, useEffect } from "react"
import { X } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Alert, AlertDescription } from "@/components/ui/alert"

export function EnvironmentBanner() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Only show banner if we're in mock data mode
    const useMockData = process.env.NEXT_PUBLIC_USE_MOCK_DATA === "true"
    setIsVisible(useMockData)
  }, [])

  if (!isVisible) return null

  return (
    <Alert className="rounded-none border-0 py-2 px-4 bg-amber-500 text-amber-950">
      <div className="flex items-center justify-between w-full">
        <AlertDescription className="text-xs sm:text-sm font-medium">
          🧪 Demo Mode: Using mock data for demonstration purposes
        </AlertDescription>
        <Button
          variant="ghost"
          size="sm"
          className="h-6 w-6 p-0 text-amber-950 hover:bg-amber-400 hover:text-amber-950"
          onClick={() => setIsVisible(false)}
        >
          <X className="h-3 w-3" />
          <span className="sr-only">Close</span>
        </Button>
      </div>
    </Alert>
  )
}

